<template>
   <section class="content-container">
      <SectionTabs :tabs="tab_component"/>
      <router-view/>
   </section>
</template>

<!-- <template>
   <div class="news">
      <div class="news-sidebar">
         <SideBar />
      </div>
      <div class="news-container">

         <img src="@/assets/img/forma_1.svg" alt="" class="shape-one" />
         <img src="@/assets/img/forma_3.svg" alt="" class="shape-tw" />
         <img src="../assets/img/forma_2.svg" alt="" class="shape-tr" /> 
         <div class="news-body">
            <div class="news-body-top">
               <SectionTitle :title="title" subtitle="" />
               <ProfileNav />
            </div>
            <div class="tabs">
               <SectionTabs :tabs="tab_component" />
            </div>

            <router-view />
            <footerSec></footerSec>
         </div>
      </div>
   </div>
</template> -->

<script>
import SectionTabs from "@/components/Section/SectionTabs.vue";
import { mapActions } from "vuex";
/* import SideBar from "@/components/SideBar.vue";
import SectionTitle from "@/components/Section/SectionTitle.vue";
import ProfileNav from "@/components/Profile/ProfileNav.vue";
import footerSec from "@/components/Section/SectionFooter.vue"; */

export default {
   components: {
      SectionTabs,
/*       SideBar,
      SectionTitle,
      ProfileNav,
      footerSec */
   },
   data() {
      return {
         title: "Noticias",
         tab_component: [{
            name: "Noticias",
            route: "news-list",
         }, {
            name: "Agregar",
            route: "news-add",
         }, {
            name: "Categorías",
            route: "news-categories"
         }],
      };
   },

   mounted() {
      setInterval(() => {
         this.checkIfUserIsLogged();
      }, 100);

      //this.getNewsCategories({ id_empresa: this.$ls.get("user").empresa[0].id_empresa });
   },

   methods: {
      ...mapActions("newsModule", ["getNewsCategories"]),
   }
};
</script>